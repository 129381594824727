<template>
  <div>
    <div
        style="font-weight: bold;padding: 10px 0;width: 100%;text-align: center;;background-color: #143771;color: #fff9ff;margin-bottom: 5px">
      Online: <span style="color: deepskyblue">{{ count }}</span> 명
    </div>
    <ul style="display: flex;justify-content: space-evenly;flex-wrap: wrap;list-style: none;width: 100%;padding: 0!important;margin: 0!important;">
      <li v-for="item in onlineUsers"
          style="width: 49%;text-align: center;padding: 2px 0;border-radius: 5px;margin-top: 1px" class="bg-warning">
        <div style="cursor: pointer;font-size: 12px;" @click="editUser(item.id,managerConst.MODE_EDIT)">
          <span v-if="item.ipcountry && item.ipcountry == 'US'"><img src="../../assets/images/ipcountry/US.gif" alt=""
                                                                     style="height: 12px"></span>
          <span v-if="item.ipcountry && item.ipcountry == 'KR'"><img src="../../assets/images/ipcountry/KR.gif" alt=""
                                                                     style="height: 12px"></span>
          <span v-if="item.ipcountry && item.ipcountry == 'CN'"><img src="../../assets/images/ipcountry/CN.gif" alt=""
                                                                     style="height: 12px"></span>
          <span v-if="item.ipcountry && item.ipcountry == 'HK'"><img src="../../assets/images/ipcountry/CN.gif" alt=""
                                                                     style="height: 12px"></span>
          <span v-if="item.ipcountry && item.ipcountry == 'JP'"><img src="../../assets/images/ipcountry/JP.gif" alt=""
                                                                     style="height: 12px"></span>
          <span v-if="item.ipcountry && item.ipcountry == 'KH'"><img src="../../assets/images/ipcountry/KH.gif" alt=""
                                                                     style="height: 12px"></span>
          <span v-if="item.ipcountry && item.ipcountry == 'PH'"><img src="../../assets/images/ipcountry/PH.gif" alt=""
                                                                     style="height: 12px"></span>
          <span v-if="item.ipcountry && item.ipcountry == 'SG'"><img src="../../assets/images/ipcountry/SG.gif" alt=""
                                                                     style="height: 12px"></span>
          <span v-if="item.ipcountry && item.ipcountry == 'TH'"><img src="../../assets/images/ipcountry/TH.gif" alt=""
                                                                     style="height: 12px"></span>
          <span v-if="item.ipcountry && item.ipcountry == 'VN'"><img src="../../assets/images/ipcountry/VN.gif" alt=""
                                                                     style="height: 12px"></span>
          {{item.nickname}}
        </div>
        <div style="font-size: 12px;cursor: pointer;" @click="editUser(item.id,managerConst.MODE_EDIT)">
          ({{ item.position }})
        </div>
        <div v-if="item.networktype" @click="editUser(item.id,managerConst.MODE_EDIT)">
          <i class="fa fa-warning"></i>
          {{ item.networktype }}
        </div>
        <!--                <div style="font-size: 12px;">({{item.logindomain}})</div>-->
        <!--                <div style="font-size: 12px;">{{item.lastLoginIp}}</div>-->
        <!--                <div style="font-size: 12px;">{{item.platform}}</div>-->
        <!--                <div style="font-size: 12px;">머니({{item.cash|comma}})</div>-->
        <!--                <div style="font-size: 12px; color: grey">Point({{item.userPoint|comma}})</div>-->
        <!--                <div style="font-size: 12px;">{{item.rank.rankName}}</div>-->
        <!--                <div>-->
        <!--                    <span style="font-size: 11px;"  v-for="sameipuser in item.sameIpUsers">-->
        <!--                        <i class="fa fa-user-friends"></i>-->
        <!--                        {{sameipuser.nickname}}</span>-->
        <!--                </div>-->
      </li>
    </ul>
  </div>


</template>

<script>

import Vue from "vue";
import UserEditToast from "./UserPanel/UserEditToast";
import {getOnlineUser} from "../../network/manager/commonRequest";
import managerConst from "../../common/administrator/managerConst";

Vue.prototype.$userEditToast = UserEditToast
export default {
  name: "OnlineUserComp",
  data() {
    return {
      onlineUsers: [],
      count: 0,
      managerConst: managerConst
    }
  },
  methods: {
    editUser(userId, mode) {
      this.$userEditToast({'userId': userId, 'mode': mode})
    },
    getOnlineUsers() {
      getOnlineUser().then(res => {
        this.onlineUsers = res.data.data
        try {
          this.count = this.onlineUsers.length
        } catch (e) {

        }

      })
    }

  },
  computed: {},
  created() {
    this.getOnlineUsers()
    setInterval(this.getOnlineUsers, 10000);
  },
  watch: {},
}
</script>

<style scoped>


</style>